<template>
  <div>
    <nav
      class="
        navbar navbar-expand-lg
        bg-light
        shadow-sm
        navbar-white
        flex-column
        px-3
        py-0
      "
    >
      <div class="d-flex justify-content-between w-100 py-2 border-bottom">
        <b-btn
          variant="transparent"
          size="sm"
          @click="$emit('toggleSidebar', true)"
        >
          <b-icon icon="text-right" variant="primary"></b-icon>
        </b-btn>

        <div class="d-flex flex-row align-items-center">
          <div class="d-flex flex-row align-items-center p-1 px-2">
            <img
              :src="image"
              width="40"
              height="40"
              class="rounded-circle mx-3"
            />
            <span class="text-muted"> {{ username }} </span>
          </div>
        </div>
      </div>
      <div class="d-flex w-100 py-3">
        <span class="text-muted ml-1"> {{ historyRouteName() }} </span>
        <span class="text-primary"> {{ currentRouteName }} </span>
      </div>
    </nav>
  </div>
</template>

<script>
import { ImageURL } from "@/utils/helper";

export default {
  props: {
    showSidebar: {
      type: Boolean,
    },
  },
  name: "Navbar",
  computed: {
    username() {
      return JSON.parse(localStorage.getItem("name"));
    },
    currentRouteName() {
      return this.$route.name;
    },
    image() {
      return JSON.parse(localStorage.getItem("image")) ?? ImageURL("user.jpg");
    },
  },
  methods: {
    historyRouteName() {
      var routeName = "";
      this.$router.history.current.matched.forEach((element, index) => {
        if (index !== this.$router.history.current.matched.length - 1)
          routeName += element.name + " > ";
      });
      return routeName;
    },
  },
};
</script>

<style></style>
