<template>
  <li :title="title" :id="title" class="px-2 w-100">
    <b-tooltip
      :disabled.sync="toggle"
      :target="title"
      :title="title"
    ></b-tooltip>
    <template v-if="!subs">
      <router-link
        :to="{ name: to, query }"
        active-class="active"
        class="sidebar_link"
        :class="[toggle ? 'justify-content-right' : 'justify-content-center']"
        :exact="exact"
      >
        <b-icon :icon="icon" class="mx-3 pt-2 h4"></b-icon>
        <span v-if="toggle">{{ title }}</span>
      </router-link>
    </template>
    <template v-else>
      <a
        @click="showSubs = !showSubs"
        v-click-outside="() => (showSubs = false)"
      >
        <router-link
          :to="{ name: to, query }"
          active-class="active"
          class="sidebar_link"
          :class="[
            toggle ? 'justify-content-between' : 'justify-content-center',
          ]"
          :exact="exact"
        >
          <div class="d-flex align-items-center">
            <b-icon :icon="icon" class="mx-3 pt-2 h4"></b-icon>
            <span v-if="toggle">{{ title }}</span>
          </div>
          <b-icon
            :icon="showSubs ? 'caret-up' : 'caret-down'"
            class="pl-2 pt-2 h4"
          ></b-icon>
        </router-link>
      </a>
      <ul v-if="showSubs" class="sidebar_link__sub">
        <li v-for="(link, idx) in subs" :key="idx">
          <router-link
            :to="{ name: link.to, query: link.query }"
            active-class="sub-active"
            class="sidebar_link"
            :class="[
              toggle ? 'justify-content-right' : 'justify-content-center',
            ]"
            :exact="exact"
          >
            <b-icon :icon="link.icon" class="mx-3 pt-2 h4"></b-icon>
            <span v-if="toggle">{{ link.title }}</span>
          </router-link>
        </li>
      </ul>
    </template>
  </li>
</template>

<script>
export default {
  props: ["title", "icon", "to", "exact", "query", "role", "subs", "toggle"],
  data() {
    return {
      showSubs: false,
    };
  },
};
</script>

<style lang="scss">
.sidebar_link {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #cac6c6;
  transition: 0.4s ease-in-out;
  border-radius: 0.25rem !important;
  padding: 0.3rem !important;
  .sidebar_link_icon,
  &__sub,
  &:hover {
    text-decoration: none;
    color: white !important;
    padding: 0;
  }
  .sidebar_link_icon,
  &.active {
    color: white !important;
    background: rgba(255, 255, 255, 0.1) !important;
    border-right: 4px solid white;
  }
  &__sub {
    list-style: none;
    background: rgba(39, 39, 70, 1) !important;
  }
}
.sub-active {
  color: white !important;
}
</style>
