<template>
  <div
    class="d-flex flex-column bg-primary"
    :class="[toggle ? 'sidebar' : 'icon-sidebar']"
  >
    <div
      class="
        d-inline-flex
        p-3
        w-100
        align-items-center
        justify-content-right
        border-bottom border-secondary
      "
      :class="[toggle ? 'justify-content-start' : 'justify-content-center']"
    >
      <img src="@/assets/logo.svg" />
      <h6 v-if="toggle" class="text-light font-weight-bold pr-3">مصرفنا</h6>
    </div>
    <ul class="list-group list-unstyled w-100 py-3 align-items-start">
      <template v-for="link in links">
        <SidebarLink
          :key="link.to"
          :title="link.title"
          :icon="link.icon"
          :to="link.to"
          :exact="link.exact"
          :query="link.query"
          :subs="link.subs"
          :toggle="toggle"
          v-if="link.roles.includes(userRole)"
        />
      </template>
    </ul>
  </div>
</template>

<script>
import SidebarLink from "./SidebarLink";

export default {
  components: {
    SidebarLink,
  },
  props: {
    links: Array,
    toggle: Boolean,
  },
};
</script>

<style lang="scss">
.sidebar {
  height: 100%;
  width: 17%;
  transition: width 2s;
  overflow-x: hidden;
}
.icon-sidebar {
  height: 100%;
  width: 100px;
  transition: width 1s;
}
@media screen and (max-width: 850px) {
  .sidebar {
    display: none !important;
  }
  .icon-sidebar {
    display: block !important;
  }
}
</style>
