<template>
  <b-container id="layout" fluid>
    <Sidebar :links="links" :toggle="showSidebar"></Sidebar>
    <div class="main">
      <Navbar @toggleSidebar="showSidebar = !showSidebar"></Navbar>
      <div v-if="hasRequest" class="req-loader">
        <img src="@/assets/logo.svg" width="180" alt="loader_logo" />
      </div>
      <router-view class="route"></router-view>
    </div>
  </b-container>
</template>

<script>
// @ is an alias to /src
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
export default {
  components: {
    Navbar,
    Sidebar,
  },
  data() {
    return {
      links: [
        {
          title: "الأحصائيات",
          icon: "bar-chart-fill",
          to: "الأحصائيات",
          roles: ["Admin"],
        },
        {
          title: "المستخدمين",
          icon: "people",
          to: "المستخدمين",
          subs: [
            {
              title: "الأدارة",
              icon: "dot",
              to: "الأدارة",
            },
            {
              title: "مسؤولين المصارف",
              icon: "dot",
              to: "مسؤولي المصارف",
            },
            {
              title: "الزائرين",
              icon: "dot",
              to: "الزائرين",
            },
          ],
          roles: ["Admin"],
        },
        {
          title: "المصارف",
          icon: "building",
          to: "المصارف",
          roles: ["Admin"],
        },
        {
          title: "المنشورات",
          icon: "layout-wtf",
          to: "المنشورات",
          subs: [
            {
              title: "الأعلانات",
              icon: "dot",
              to: "الأعلانات",
            },
            {
              title: "المقالات",
              icon: "dot",
              to: "المقالات",
            },
            {
              title: "الأخبار",
              icon: "dot",
              to: "الأخبار",
            },
          ],
          roles: ["Admin"],
        },
        {
          title: "حول مصرفنا",
          icon: "file-earmark-font",
          to: "حول مصرفنا",
          roles: ["Admin"],
        },
        {
          title: "الشروط والأحكام",
          icon: "shield-lock",
          to: "الشروط والأحكام",
          roles: ["Admin"],
        },
        {
          title: "أحصائيات المصرف",
          icon: "bar-chart-fill",
          to: "الأحصائيات",
          roles: ["BankAdmin"],
        },
        {
          title: "مستخدمي المصرف",
          icon: "people",
          to: "مستخدمي المصرف",
          roles: ["BankAdmin"],
        },
        {
          title: "القروض",
          icon: "cash-stack",
          to: "القروض",
          roles: ["BankAdmin"],
        },
        {
          title: "التمويلات",
          icon: "building",
          to: "التمويلات",
          roles: ["BankAdmin"],
        },
        {
          title: "البطاقات",
          icon: "credit-card2-front",
          to: "البطاقات",
          roles: ["BankAdmin"],
        },
        {
          title: "حسابات الودائع",
          icon: "list-check",
          to: "حسابات الودائع",
          subs: [
            {
              title: "حسابات التوفير والأدخار",
              icon: "dot",
              to: "حسابات التوفير والأدخار",
            },
            {
              title: "حسابات الودائع الثابته",
              icon: "dot",
              to: "حسابات الودائع الثابته",
            },
            {
              title: "حسابات الودائع الوقتية",
              icon: "dot",
              to: "حسابات الودائع الوقتية",
            },
            {
              title: "حسابات جارية",
              icon: "dot",
              to: "حسابات جارية",
            },
            {
              title: "شهادة الأيداع",
              icon: "dot",
              to: "شهادة الأيداع",
            },
          ],
          roles: ["BankAdmin"],
        },
        {
          title: "التمويلات الخارجية",
          icon: "shield-lock",
          to: "التمويلات الخارجية",
          subs: [
            {
              title: "الأعتمادات المستندية",
              icon: "dot",
              to: "الأعتمادات المستندية",
            },
            {
              title: "الحوالات الخارجية",
              icon: "dot",
              to: "الحوالات الخارجية",
            },
          ],
          roles: ["BankAdmin"],
        },
        {
          title: "خطابات الضمان",
          icon: "file-earmark-lock",
          to: "خطابات الضمان",
          subs: [
            {
              title: "الداخلية",
              icon: "dot",
              to: "الداخلية",
            },
            {
              title: "الخارجية",
              icon: "dot",
              to: "الخارجية",
            },
          ],
          roles: ["BankAdmin"],
        },
        {
          title: "خدمات آخرى",
          icon: "columns-gap",
          to: "خدمات آخرى",
          subs: [
            {
              title: "الصكوك المصدقة والسفاتج",
              icon: "dot",
              to: "الصكوك المصدقة والسفاتج",
            },
            {
              title: "الأستثمارات",
              icon: "dot",
              to: "الأستثمارات",
            },
            {
              title: "صكوك المقاصة الالكترونية",
              icon: "dot",
              to: "صكوك المقاصة الالكترونية",
            },
            {
              title: "تأسيس الشركات والاكتتاب",
              icon: "dot",
              to: "تأسيس الشركات والاكتتاب",
            },
            {
              title: "كشوفات الحساب",
              icon: "dot",
              to: "كشوفات الحساب",
            },
            {
              title: "SWIFT الحوالات الخارجية",
              icon: "dot",
              to: "SWIFT الحوالات الخارجية",
            },
            {
              title: "RTGS الحوالات الداخلية",
              icon: "dot",
              to: "RTGS الحوالات الداخلية",
            },
          ],
          roles: ["BankAdmin"],
        },
        {
          title: "حول المصرف",
          icon: "file-earmark-font",
          to: "حول المصرف",
          subs: [
            {
              title: "التوطين",
              icon: "dot",
              to: "التوطين",
            },
            {
              title: "ويسترن يونيون",
              icon: "dot",
              to: "ويسترن يونيون",
            },
            {
              title: "خدمات عبر الأنترنت",
              icon: "dot",
              to: "خدمات عبر الأنترنت",
            },
          ],
          roles: ["BankAdmin"],
        },
        {
          title: "اقسام المصرف",
          icon: "file-spreadsheet-fill",
          to: "اقسام المصرف",
          subs: [
            {
              title: "ATMS",
              icon: "dot",
              to: "ATMS",
            },
            {
              title: "POS",
              icon: "dot",
              to: "POS",
            },
            {
              title: "فروع المصرف",
              icon: "dot",
              to: "فروع المصرف",
            },
          ],
          roles: ["BankAdmin"],
        },
        {
          title: "الطلبات",
          icon: "people",
          to: "الطلبات",
          subs: [
            {
              title: "طلبات أنشاء حساب",
              icon: "dot",
              to: "طلبات أنشاء حساب",
            },
            {
              title: "طلبات التقديم على القروض",
              icon: "dot",
              to: "طلبات التقديم على القروض",
            },
            {
              title: "طلبات أصدار بطاقة",
              icon: "dot",
              to: "طلبات أصدار بطاقة",
            },
          ],
          roles: ["BankReviewer"],
        },
        {
          title: "تسجيل الخروج",
          icon: "arrow-right-circle",
          to: "Logout",
          roles: ["Admin", "BankAdmin", "BankReviewer"],
        },
      ],
      showSidebar: true,
    };
  },
  computed: {
    hasRequest() {
      return this.$store.state.loader.requests.length;
    },
  },
};
</script>
<style lang="scss" scoped>
#layout {
  display: flex;
  padding: 0;
  height: 100vh;
  background: #e4e9f2;
  overflow: hidden;
  .main {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .route {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 16px 32px;
  }
}
</style>
